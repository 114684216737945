import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private subscriptions = new Subscription();

  private successTitle = 'TOAST.TITLE.SUCCESS';
  private warningTitle = 'TOAST.TITLE.WARNING';
  private errorTitle = 'TOAST.TITLE.ERROR';
  private infoTitle = 'TOAST.TITLE.INFO';

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
  }

  success(message: string) {
    let title = '';
    let text = '';

    this.subscriptions.add(
      this.translate.get(this.successTitle).subscribe((res) => (title = res))
    );
    this.subscriptions.add(
      this.translate.get(message).subscribe((res) => (text = res))
    );
    this.toastr.success(text, title);

    this.subscriptions.unsubscribe();
  }

  error(message: string) {
    let title = '';
    let text = '';

    this.subscriptions.add(
      this.translate.get(this.errorTitle).subscribe((res) => (title = res))
    );
    this.subscriptions.add(
      this.translate.get(message).subscribe((res) => (text = res))
    );
    this.toastr.error(text, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });

    this.subscriptions.unsubscribe();
  }

  info(message: string) {
    let title = '';
    let text = '';

    this.subscriptions.add(
      this.translate.get(this.infoTitle).subscribe((res) => (title = res))
    );
    this.subscriptions.add(
      this.translate.get(message).subscribe((res) => (text = res))
    );
    this.toastr.info(text, title);

    this.subscriptions.unsubscribe();
  }

  warning(message: string) {
    let title = '';
    let text = '';

    this.subscriptions.add(
      this.translate.get(this.warningTitle).subscribe((res) => (title = res))
    );
    this.subscriptions.add(
      this.translate.get(message).subscribe((res) => (text = res))
    );
    this.toastr.warning(text, title);

    this.subscriptions.unsubscribe();
  }
}
